/* src/App.css */
.banner {
  display: flex;
  justify-content: space-between; /* 左右对齐 */
  align-items: center; /* 垂直居中 */
  padding: 10px 20px;
  background-color: #3b7dba;
  border-bottom: 1px solid #ccc;
}
.logo {
  flex: 1;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size: 30px;
}
.title {
  flex: 1;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.title h1 {
  text-align: center;
}
.nav {
  flex: 1;
  display: flex;
  gap: 20px; /* 控制导航项之间的间距 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.nav div {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.nav div a {
  text-decoration: none;
  color: black;
}

.nav div a:hover {
  color: blue; /* 鼠标悬停时改变颜色 */
}

/* 模态框背景 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 模态框内容 */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-content input {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
}

.modal-content button {
  margin: 10px 5px;
  padding: 10px;
}
