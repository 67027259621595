.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 使输入框和按钮居中 */
  justify-content: center;
  height: 100vh; /* 使内容垂直居中 */
  margin-top: -200px;
}

.inputs {
  display: flex;
  gap: 10px; /* 输入框之间的间距 */
  margin-bottom: 20px; /* 输入框和按钮之间的间距 */
}

button {
  padding: 20px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 40px;
}

button:hover {
  background-color: #0056b3;
}

h1 {
  margin-bottom: 30px; /* 标题和输入框之间的距离 */
}
